<div class="bg-primary-100">
  <div
    class="container mx-auto flex flex-col px-4 py-8 max-md:items-center max-md:space-y-8 md:flex-row md:justify-center md:py-6"
  >
    <div class="flex items-center space-x-2">
      <a
        class="shrink-0 focus-visible:text-primary-400 focus-visible:outline-secondary-300"
        ariaCurrentWhenActive="page"
        routerLink="/"
      >
        <img
          ngSrc="../../../assets/images/brands/moura-facil-dark.svg"
          width="120"
          height="30"
          alt=""
        />

        <h3 class="sr-only">Moura Fácil</h3>
      </a>

      <a
        class="shrink-0 focus-visible:text-primary-400 focus-visible:outline-secondary-300"
        href="https://moura.com.br/"
      >
        <img
          ngSrc="../../../assets/images/brands/moura-dark.svg"
          width="94"
          height="36"
          alt=""
        />

        <h3 class="sr-only">Moura Fácil</h3>
      </a>
    </div>

    <div class="hidden" id="armored_website">
      <param id="aw_preload" value="true" />
      <param id="aw_use_cdn" value="true" />
    </div>
  </div>
</div>

<div class="bg-primary-300 text-grey-50">
  <div
    class="container mx-auto space-y-10 px-4 pb-20 pt-10 xl:space-y-12 xl:py-12"
  >
    <div class="justify-between max-xl:space-y-10 xl:flex">
      <div class="space-y-4 xl:space-y-6">
        <div class="space-y-2 max-xl:text-center">
          <h3 class="font-poppins font-semibold md:text-lg">
            Formas de pagamento
          </h3>

          <p class="text-sm font-medium md:text-base md:font-normal">
            Aceitamos pagamentos via cartão de crédito/débito, dinheiro e PIX.
          </p>
        </div>

        <div class="flex items-center space-x-8 max-xl:justify-center">
          <img
            class="md:h-[28px] md:w-[28px]"
            ngSrc="../../../assets/images/icons/payment-pix-white.svg"
            width="20"
            height="20"
            alt="PIX"
          />

          <img
            class="md:h-[28px] md:w-[28px]"
            ngSrc="../../../assets/images/icons/payment-mastercard-white.svg"
            width="24"
            height="22"
            alt="Mastercard"
          />

          <img
            class="md:h-[28px] md:w-[28px]"
            ngSrc="../../../assets/images/icons/payment-elo-white.svg"
            width="24"
            height="18"
            alt="ELO"
          />

          <img
            class="md:h-[28px] md:w-[28px]"
            ngSrc="../../../assets/images/icons/payment-visa-white.svg"
            width="24"
            height="18"
            alt="Visa"
          />
        </div>
      </div>

      <div class="flex space-x-4 xl:space-x-16">
        <ul class="space-y-10 max-xl:w-1/2">
          <li>
            <a
              class="text-sm font-semibold"
              (click)="
                clickedMenuItem.emit({
                  footer_item: 'Sobre'
                })
              "
              ariaCurrentWhenActive="page"
              routerLink="/sobre"
            >
              Sobre o serviço
            </a>
          </li>
          <li>
            <a
              class="text-sm font-semibold"
              (click)="
                clickedMenuItem.emit({
                  footer_item: 'Contato'
                })
              "
              ariaCurrentWhenActive="page"
              routerLink="/contato"
            >
              Fale conosco
            </a>
          </li>
          <li>
            <a
              class="text-sm font-semibold"
              (click)="
                clickedMenuItem.emit({
                  footer_item: 'FAQ'
                })
              "
              ariaCurrentWhenActive="page"
              routerLink="/faq"
            >
              Perguntas frequentes
            </a>
          </li>
          <li>
            <a
              class="text-sm font-semibold"
              href="https://moura.com.br/blog"
              target="_blank"
              (click)="
                clickedMenuItem.emit({
                  footer_item: 'Blog'
                })
              "
            >
              Nosso blog

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-arrow-up-right"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"
                />
              </svg>
            </a>
          </li>
        </ul>

        <ul class="space-y-10 max-xl:w-1/2">
          <li>
            <a
              class="text-sm font-semibold"
              href="https://mourafacil.moura.com.br/conheca-mais/"
              (click)="
                clickedMenuItem.emit({
                  footer_item: 'Conheça mais'
                })
              "
            >
              Conheça mais

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-arrow-up-right"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"
                />
              </svg>
            </a>
          </li>
          <li>
            <a
              class="text-sm font-semibold"
              routerLink="/politica-de-privacidade"
              (click)="
                clickedMenuItem.emit({
                  footer_item: 'Política de privacidade'
                })
              "
            >
              Política de privacidade
            </a>
          </li>
          <li>
            <a
              class="text-sm font-semibold"
              routerLink="/politica-de-marketing-digital"
              (click)="
                clickedMenuItem.emit({
                  footer_item: 'Política de marketing digital'
                })
              "
            >
              Política de marketing digital
            </a>
          </li>
          <li>
            <a
              class="text-sm font-semibold"
              href="https://www.canaldeetica.com.br/grupomoura/"
              target="_blank"
              (click)="
                clickedMenuItem.emit({
                  footer_item: 'Canal de ética'
                })
              "
            >
              Canal de ética

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-arrow-up-right"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"
                />
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="border-t border-primary-200 max-md:ml-1 max-md:mr-1"></div>

    <div class="max-md:space-y-8 md:flex md:items-center md:justify-between">
      <div class="space-y-8">
        <div class="space-y-4 max-md:text-center">
          <span class="inline-flex items-center space-x-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-telephone-fill"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
              />
            </svg>

            <a
              class="font-poppins font-semibold"
              href="tel:{{ phoneNumber }}"
              (click)="clickedPhone.emit()"
            >
              {{ phoneNumber }}
            </a>
          </span>

          <p class="text-sm font-semibold">
            Atendimento das 9h às 18h (dias úteis)
          </p>
        </div>

        <ul class="flex items-center space-x-8 max-md:justify-center">
          <li>
            <a
              href="https://linkedin.com/company/grupo-moura"
              target="_blank"
              (click)="
                clickedSocialItem.emit({
                  social_item: 'LinkedIn'
                })
              "
            >
              <img
                ngSrc="../../../assets/images/icons/social-linkedin-white.svg"
                width="24"
                height="24"
                alt=""
              />

              <span class="sr-only">LinkedIn</span>
            </a>
          </li>
          <li>
            <a
              href="https://youtube.com/user/bateriasmouratv"
              target="_blank"
              (click)="
                clickedSocialItem.emit({
                  social_item: 'YouTube'
                })
              "
            >
              <img
                ngSrc="../../../assets/images/icons/social-youtube-white.svg"
                width="24"
                height="16"
                alt=""
              />

              <span class="sr-only">YouTube</span>
            </a>
          </li>
          <li>
            <a
              href="https://facebook.com/bateriasmoura"
              target="_blank"
              (click)="
                clickedSocialItem.emit({
                  social_item: 'Facebook'
                })
              "
            >
              <img
                ngSrc="../../../assets/images/icons/social-facebook-white.svg"
                width="24"
                height="24"
                alt=""
              />

              <span class="sr-only">Facebook</span>
            </a>
          </li>
          <li>
            <a
              href="https://x.com/bateriasmoura"
              target="_blank"
              (click)="
                clickedSocialItem.emit({
                  social_item: 'X'
                })
              "
            >
              <img
                ngSrc="../../../assets/images/icons/social-x-white.svg"
                width="24"
                height="22"
                alt=""
              />

              <span class="sr-only">X</span>
            </a>
          </li>
          <li>
            <a
              href="https://instagram.com/bateriasmoura"
              target="_blank"
              (click)="
                clickedSocialItem.emit({
                  social_item: 'Instagram'
                })
              "
            >
              <img
                ngSrc="../../../assets/images/icons/social-instagram-white.svg"
                width="24"
                height="24"
                alt=""
              />

              <span class="sr-only">Instagram</span>
            </a>
          </li>
        </ul>
      </div>

      <p class="text-sm font-medium max-md:text-center">
        Copyright © {{ currentYear }} - Acumuladores Moura S. A.<br />
        CNPJ: 01.098.290/0001-10<br />
        Todos os direitos reservados.<br />
        <a class="undeline" href="https://maps.app.goo.gl/MUtA9xWkksYD819j8">
          Rua Padre Carapuceiro, 706
        </a>
      </p>
    </div>
  </div>
</div>
