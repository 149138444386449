<nav class="toolbar elevation-1 fixed left-0 top-0 z-[60] w-full bg-grey-50">
  <div
    class="container mx-auto flex items-center justify-between px-4 max-md:h-[50px] md:h-[56px] xl:h-[72px]"
  >
    <a
      class="shrink-0 focus-visible:text-primary-400 focus-visible:outline-secondary-300"
      (click)="hideMenu(); resetFormValidity()"
      ariaCurrentWhenActive="page"
      routerLink="/"
    >
      <h1 class="sr-only">{{ pageTitle }}</h1>

      <h2 class="sr-only">Delivery Oficial da Moura</h2>

      <img
        class="block md:hidden"
        ngSrc="../assets/images/brands/moura-facil-mobile.svg"
        width="158"
        height="36"
        alt="Moura Fácil"
      />

      <img
        class="hidden md:block"
        ngSrc="../assets/images/brands/moura-facil-tablet-desk.svg"
        width="270"
        height="44"
        alt="Moura Fácil"
      />
    </a>

    <div class="flex items-center space-x-4 xl:hidden">
      <div class="flex items-center space-x-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-telephone-fill"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
          />
        </svg>

        <span class="hidden md:block">Ligue agora</span>

        <a
          class="btn btn-sm btn-outline"
          href="tel:{{ phoneNumber }}"
          (click)="clickedPhone.emit()"
        >
          {{ phoneNumber }}
        </a>
      </div>

      <button
        class="btn btn-sm btn-ghost h-8 w-8 p-0"
        type="button"
        (click)="toggleMenu()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          fill="currentColor"
          class="bi bi-list"
          viewBox="0 0 16 16"
          *ngIf="!menuIsOpen"
        >
          <path
            fill-rule="evenodd"
            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
          />
        </svg>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          fill="currentColor"
          class="bi bi-x-lg"
          viewBox="0 0 16 16"
          *ngIf="menuIsOpen"
        >
          <path
            d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"
          />
        </svg>

        <span class="sr-only">Abrir/fechar menu</span>
      </button>
    </div>

    <div class="hidden items-center space-x-8 xl:flex">
      <ul class="items-center space-x-4">
        <li
          class="inline"
          [ngClass]="{
            hidden: hideCheckPricesOnScroll && hideCheckPrices
          }"
          *ngIf="renderCheckPrices"
        >
          <a
            class="btn btn-sm btn-primary"
            (click)="
              clickedHeaderItem.emit({
                header_item: 'Confira os preços'
              });
              clickedCheckPrices.emit()
            "
            ariaCurrentWhenActive="page"
            routerLink="/"
          >
            Confira os preços
          </a>
        </li>
        <li class="inline">
          <a
            class="btn btn-sm btn-ghost"
            (click)="
              clickedHeaderItem.emit({
                header_item: 'Perguntas'
              })
            "
            ariaCurrentWhenActive="page"
            routerLink="/faq"
          >
            Perguntas Frequentes
          </a>
        </li>
        <li class="inline">
          <a
            class="btn btn-sm btn-ghost"
            (click)="
              clickedHeaderItem.emit({
                header_item: 'Sobre'
              })
            "
            ariaCurrentWhenActive="page"
            routerLink="/sobre"
          >
            Sobre
          </a>
        </li>
        <li class="inline">
          <a
            class="btn btn-sm btn-ghost"
            (click)="
              clickedHeaderItem.emit({
                header_item: 'Fale conosco'
              })
            "
            ariaCurrentWhenActive="page"
            routerLink="/contato"
          >
            Fale conosco
          </a>
        </li>
      </ul>

      <div class="items-center space-x-2 xl:flex">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-telephone-fill"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
          />
        </svg>

        <span>Ligue agora</span>

        <a
          class="btn btn-md btn-outline"
          href="tel:{{ phoneNumber }}"
          (click)="clickedPhone.emit()"
        >
          <span class="text-base">{{ phoneNumber }}</span>
        </a>
      </div>
    </div>
  </div>
</nav>

<nav
  class="fixed left-0 top-0 z-50 h-svh w-full bg-grey-50 pb-[56px] pt-[50px] md:pt-[56px] xl:hidden"
  *ngIf="menuIsOpen"
>
  <ul class="h-full overflow-y-scroll overscroll-y-contain pb-[56px]">
    <li>
      <a
        class="flex items-center space-x-4 px-4 py-6 transition-colors hover:bg-primary-50 focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-0 focus-visible:outline-secondary-300 active:bg-primary-200 md:p-8"
        (click)="
          clickedMenuItem.emit({
            menu_item: 'Home'
          });
          hideMenu()
        "
        ariaCurrentWhenActive="page"
        routerLink="/"
      >
        <div class="flex grow items-center space-x-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="currentColor"
            class="bi bi-house-door"
            viewBox="0 0 16 16"
          >
            <path
              d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4z"
            />
          </svg>

          <span class="font-poppins font-semibold md:text-lg">Início</span>
        </div>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-chevron-right"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
          />
        </svg>
      </a>
    </li>
    <li>
      <a
        class="flex items-center space-x-4 px-4 py-6 transition-colors hover:bg-primary-50 focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-0 focus-visible:outline-secondary-300 active:bg-primary-200 md:p-8"
        (click)="
          clickedMenuItem.emit({
            menu_item: 'Sobre'
          });
          hideMenu()
        "
        ariaCurrentWhenActive="page"
        routerLink="/sobre"
      >
        <div class="flex grow items-center space-x-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-info-circle"
            viewBox="0 0 16 16"
          >
            <path
              d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"
            />
            <path
              d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"
            />
          </svg>

          <span class="font-poppins font-semibold md:text-lg">
            Sobre Moura Fácil
          </span>
        </div>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-chevron-right"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
          />
        </svg>
      </a>
    </li>
    <li>
      <a
        class="flex items-center space-x-4 px-4 py-6 transition-colors hover:bg-primary-50 focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-0 focus-visible:outline-secondary-300 active:bg-primary-200 md:p-8"
        (click)="
          clickedMenuItem.emit({
            menu_item: 'Perguntas'
          });
          hideMenu()
        "
        ariaCurrentWhenActive="page"
        routerLink="/faq"
      >
        <div class="flex grow items-center space-x-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="currentColor"
            class="bi bi-question-lg"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14"
            />
          </svg>

          <span class="font-poppins font-semibold md:text-lg">
            Perguntas frequentes
          </span>
        </div>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-chevron-right"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
          />
        </svg>
      </a>
    </li>
    <li>
      <a
        class="flex items-center space-x-4 px-4 py-6 transition-colors hover:bg-primary-50 focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-0 focus-visible:outline-secondary-300 active:bg-primary-200 md:p-8"
        (click)="
          clickedMenuItem.emit({
            menu_item: 'Contato'
          });
          hideMenu()
        "
        ariaCurrentWhenActive="page"
        routerLink="/contato"
      >
        <div class="flex grow items-center space-x-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="currentColor"
            class="bi bi-chat-dots"
            viewBox="0 0 16 16"
          >
            <path
              d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
            />
            <path
              d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9 9 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.4 10.4 0 0 1-.524 2.318l-.003.011a11 11 0 0 1-.244.637c-.079.186.074.394.273.362a22 22 0 0 0 .693-.125m.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6-3.004 6-7 6a8 8 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a11 11 0 0 0 .398-2"
            />
          </svg>

          <span class="font-poppins font-semibold md:text-lg">
            Fale conosco
          </span>
        </div>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-chevron-right"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
          />
        </svg>
      </a>
    </li>
    <li>
      <a
        class="flex items-center space-x-4 px-4 py-6 transition-colors hover:bg-primary-50 focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-0 focus-visible:outline-secondary-300 active:bg-primary-200 md:p-8"
        routerLink="/politica-de-privacidade"
        (click)="
          clickedMenuItem.emit({
            menu_item: 'Política de privacidade'
          });
          hideMenu()
        "
      >
        <div class="flex grow items-center space-x-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="currentColor"
            class="bi bi-cookie"
            viewBox="0 0 16 16"
          >
            <path
              d="M6 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m4.5.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3m-.5 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"
            />
            <path
              d="M8 0a7.96 7.96 0 0 0-4.075 1.114q-.245.102-.437.28A8 8 0 1 0 8 0m3.25 14.201a1.5 1.5 0 0 0-2.13.71A7 7 0 0 1 8 15a6.97 6.97 0 0 1-3.845-1.15 1.5 1.5 0 1 0-2.005-2.005A6.97 6.97 0 0 1 1 8c0-1.953.8-3.719 2.09-4.989a1.5 1.5 0 1 0 2.469-1.574A7 7 0 0 1 8 1c1.42 0 2.742.423 3.845 1.15a1.5 1.5 0 1 0 2.005 2.005A6.97 6.97 0 0 1 15 8c0 .596-.074 1.174-.214 1.727a1.5 1.5 0 1 0-1.025 2.25 7 7 0 0 1-2.51 2.224Z"
            />
          </svg>

          <span class="font-poppins font-semibold md:text-lg">
            Política de privacidade
          </span>
        </div>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-chevron-right"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
          />
        </svg>
      </a>
    </li>
    <li>
      <a
        class="flex items-center space-x-4 px-4 py-6 transition-colors hover:bg-primary-50 focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-0 focus-visible:outline-secondary-300 active:bg-primary-200 md:p-8"
        routerLink="/politica-de-marketing-digital"
        (click)="
          clickedMenuItem.emit({
            menu_item: 'Política de marketing digital'
          });
          hideMenu()
        "
      >
        <div class="flex grow items-center space-x-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="currentColor"
            class="bi bi-clipboard"
            viewBox="0 0 16 16"
          >
            <path
              d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z"
            />
            <path
              d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z"
            />
          </svg>

          <span class="font-poppins font-semibold md:text-lg">
            Política de marketing
          </span>
        </div>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-chevron-right"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
          />
        </svg>
      </a>
    </li>
    <li>
      <a
        class="flex items-center space-x-4 px-4 py-6 transition-colors hover:bg-primary-50 focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-0 focus-visible:outline-secondary-300 active:bg-primary-200 md:p-8"
        href="https://www.canaldeetica.com.br/grupomoura/"
        target="_blank"
        (click)="
          clickedMenuItem.emit({
            menu_item: 'Canal de ética'
          });
          hideMenu()
        "
      >
        <div class="flex grow items-center space-x-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="currentColor"
            class="bi bi-people"
            viewBox="0 0 16 16"
          >
            <path
              d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"
            />
          </svg>

          <span class="font-poppins font-semibold md:text-lg">
            Canal de ética
          </span>
        </div>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-arrow-up-right"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"
          />
        </svg>
      </a>
    </li>
  </ul>
</nav>
